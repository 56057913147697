@import url('https://fonts.googleapis.com/css2?family=PT+Mono&family=Unbounded:wght@400;700;900&display=swap');

body {
  text-align: center;
}

h1 {
  color: hotpink;
  font-family: 'PT Mono', monospace, sans-serif;
  font-size: 55px;
}

p {
  font-family: 'PT Mono', monospace, sans-serif;
  line-height: 1.5em;
}

a {
  color: hotpink;
}



@media (max-width: 600px) {
  h1 {
    font-size: 40px;
  }
}
