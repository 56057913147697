@import "https://fonts.googleapis.com/css2?family=PT+Mono&family=Unbounded:wght@400;700;900&display=swap";
body {
  text-align: center;
}

h1 {
  color: #ff69b4;
  font-family: PT Mono, monospace, sans-serif;
  font-size: 55px;
}

p {
  font-family: PT Mono, monospace, sans-serif;
  line-height: 1.5em;
}

a {
  color: #ff69b4;
}

@media (max-width: 600px) {
  h1 {
    font-size: 40px;
  }
}

/*# sourceMappingURL=index.25fe3f4c.css.map */
